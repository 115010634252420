@import "styles/mixings.scss";
@import url("https://use.typekit.net/wxm8sfl.css");
//@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600&display=swap");
:root {
  --kt-primary-active: #b9122e;
  --primary-color-dark: #93041c;
  --primary-color-light: #e2e2e2;
  --secondary-color-dark: #3c3c3c;
  --bs-btn-disabled-bg: #cccccc;
  --secondary-color-light: #cccccc;
  --background-color-grey: #fff;
  --primary-color-green: #035a36;

  --color-grey-dark: #999999;
  --color-grey-medium: #cccccc;
  --color-grey-light: #f4f4f4;
  --color-black: #000;
  --color-white: #fff;

  --panels-background-dark: #f7f8ff;
  --panels-background-light: #ffffff;

  --header-background-color: #fff;
  --footer-background-color: #fff;

  --bedges-blue: #e8f6fe;
  --bedges-green: #ade6c8;

  --border-color: #f4f4f4;
  --border-radius: 10px;
  --border-radius-small: 4px;
  --border-radius-medium: 10px;
  --border-radius-large: 50%;

  --active-background: #b5d2f7;
  --active-color: #3c3c3c;

  --section-padding: 1rem;
  --section-margin: 1rem; //section is parent of block
  --section-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);

  --block-margin: 0.5rem; //block is child of scetion
  --block-padding: 0.5rem;
  --section-shadow: 0 0.5rem 0.3rem rgb(0 0 0 / 15%);

  --button-margin: 0.5rem;
  --button-padding: 0.5rem 2rem;
  --link-margin: 0.5rem;
  --form-field-margin: 0.5rem;

  --font-family-content: "Open Sans", sans-serif;
  //--font-family-headers: 'Orbitron', sans-serif;
  --font-family-headers: "Wix Madefor Display", sans-serif;
  --chart-green: #5f8672; //chart line
  --chart-green-light: #ade6c8; //
  --chart-red: #db614f; //chart line
  --chart-red-light: #f8a79b; //

  --text-color: #3c3c3c;
  --text-bold-color: #000000;
  --text-color-light: #ffffff;
  --text-color-pale: #808080;

  --footer-link-color: #000;

  --retail-catalog-desc-bg-color: rgba(254, 254, 254, 0.6);
  --retail-catalog-desc-text-color: #000;

  --text-size-small: 0.75rem;
  --text-size-medium: 0.9rem;
  --text-size-large: 1rem;
  --text-size-xl: 1.5rem;
  --text-weight-content: 400;
  --text-weight-titles: 700;

  --text-line-height: 1.5rem;

  --primary-active-link-color: var(--primary-color-dark);
}
.navigation-header a {
  text-decoration: none;
  color: var(--primary-color-dark);
  &.active {
    color: var(--primary-color-dark);
  }
  &.separator {
    color: #000;
  }
}
.btn-primary-red {
  background-color: var(--primary-color-light) !important;
  color: var(--active-color) !important;

  i {
    color: var(--active-color) !important;
  }

  &:hover {
    background-color: var(--primary-color-dark) !important;
    color: white !important;
    i,
    span {
      color: white !important;
    }
  }
}
.bg-red {
  background-color: var(--primary-color-dark) !important;
  color: white !important;
}
.btn-red {
  background-color: var(--primary-color-dark) !important;
  color: white !important;
  i,
  span {
    color: white !important;
  }

  &:hover {
    background-color: var(--primary-color-light) !important;
    color: var(--active-color) !important;

    i {
      color: var(--active-color) !important;
    }
  }
}
.bg-primary {
  background-color: var(--primary-color-dark) !important;
}
#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#root {
  height: 100%;
}
#main {
  width: 100%;
  height: 100%;
  text-align: center;
  .container {
    text-align: left;
  }
  & > div {
    display: table-row;
    width: 100%;
  }
  & > div > div {
    display: table-cell;
    width: 100%;
  }
  header > div {
    height: 50px;
  }
  main > div {
    height: 100%;
  }
  ///progressbar///
  .bar {
    width: 0px;
    height: 1rem;
    transition: width 0.15s ease-out;
    border-radius: 100px;
    box-shadow: inset -1px -1px 10px rgb(0 0 0 / 0.5);
    background-color: #1cf782;
  }
  ///progress bar ends
  .link {
    cursor: pointer;
    opacity: 0.7;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      opacity: 1;
    }
  }

  @keyframes scaleIt {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.3);
    }
  }
}
.navigation-header {
  a {
    font-family: var(--font-family-headers);
    font-weight: var(--text-weight-content);
    color: var(--primary-color-dark);
    letter-spacing: 0.075rem;
  }
}
.blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 24;
}

.dark .blocker-inner {
  background: rgba(0, 0, 0, 1);
}
.dark .blocker {
  background: rgba(0, 0, 0, 0.95);
}

.blocker-inner {
  background: rgba(255, 255, 255, 1);
  width: 33%;
  animation: stretch 3s linear;
  padding: 2rem;
  @include media-breakpoint-max(sm) {
    width: 80%;
    animation: stretchMobile 3s linear;
  }
  p {
    text-align: center;
  }
}
.block {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: var(--block-padding);

  span {
    padding: 0 var(--block-padding);
    background-color: transparent;
    font-size: var(--text-size-small);
  }
}
.block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: var(--panels-background-dark);
  padding: var(--block-padding);
  &.pale {
    background: var(--panels-background-dark);
    border-radius: var(--border-radius);
  }
  &.success {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    background: #ade6c8;
    color: white;
    padding: var(--block-padding);
    align-content: flex-end;
    flex-wrap: wrap;
  }
  &.error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    background: #f6dcd8;
    color: white;
    padding: var(--block-padding);
    align-content: flex-end;
    flex-wrap: wrap;
  }
  span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
  }
  em {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 700;
  }
}

button.reset {
  margin-top: 1.8rem !important;
}
.inline {
  display: inline-block;
  background: #e2e2e2;
  margin-top: 2rem;
}
main {
  flex: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Orbitron", sans-serif;
  font-weight: 400;
  font-style: normal;
}
h3 {
  text-align: center;
  padding: 2rem;
}
.card h3 {
  text-align: left;
}
/**Header and Navigation*/
.header {
  // border-bottom:5px solid  var(--color-grey-light);
  box-shadow: var(--section-shadow);
  padding-left: 1rem;
  a {
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
  .dropdown-item {
    &:hover {
      cursor: pointer;
    }
  }
}
@include media-breakpoint-min(xs) {
  .login-header {
    display: none;
  }
}
.logo {
  width: 70%;
  margin: 0.5rem 0;
}

/**Footer Component*/
.Twilio {
  button {
    background-image: linear-gradient(
      to top,
      var(--primary-color-dark),
      var(--primary-color-dark)
    );
  }
}
.center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.title {
  position: relative;
  font-size: 1.7rem;
  -webkit-text-stroke: 0.1vw var(--secondary-color-light);
  text-transform: uppercase;
  color: white;
  &:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    color: var(--primary-color-dark);

    -webkit-text-stroke: 0vw var(--secondary-color-light);
    border-right: 2px solid #ffffff;
    overflow: hidden;
    /* transition:animate 6s linear infinite; */
    animation: animate 6s linear infinite;
  }
}
.logo {
  position: relative;
  font-size: 1.7rem;
  -webkit-text-stroke: 0.1vw var(--secondary-color-light);
  text-transform: uppercase;
  color: white;
  width: 156px;
  &:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    color: var(--primary-color-dark);

    -webkit-text-stroke: 0vw var(--secondary-color-light);
    border-right: 2px solid #ffffff;
    overflow: hidden;
    /* transition:animate 6s linear infinite; */
    animation: animate 6s linear infinite;
  }
}

.dark h2.logo::before {
  border-right: 2px solid #000000;
}
@keyframes animate {
  0% {
    width: 0;
  }
  70% {
    width: 49%;
  }
}

.footer {
  border-top: 5px solid var(--color-grey-light);
  box-shadow: var(--section-shadow);
  --bs-gutter-x: 0;

  .footer-links {
    list-style: none;
    display: flex;
    li {
      padding: 0 5px;
      border-right: 1px solid var(--footer-link-color);
      display: inline-flex;
      &:last-child {
        padding: 0 10px;
        border-right: 0;
        display: inline-flex;
      }
    }
    a {
      color: var(--footer-link-color);
    }
  }
  h2.logo {
    position: relative;
    font-size: 0.5rem;
    -webkit-text-stroke: 0.1vw #424242;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1rem;
    width: 235px;
    &:before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 103px;
      width: 0%;
      height: 100%;
      color: #93041c;
      -webkit-text-stroke: 0vw #515461;
      border-right: 2px solid #93041c;
      overflow: hidden;
      animation: none;
    }
  }
}
.hgs-logo {
  height: 3rem;
}
.fields-title {
  text-transform: capitalize;
}
.array-field-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.component {
  background-color: var(--color-grey-light);
  border-radius: var(--border-radius);
  padding: var(--block-padding);
  .component {
    background-color: var(--color-white);
    padding: var(--section-padding);
  }
}

.container {
  .accordion-button:focus {
    border: 0;
  }
  .accordion-button:not(.collapsed) {
    color: var(--primary-color-light);
    background-color: var(--primary-color-dark);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--primary-color-light);
  }
}

.add-photo {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @include media-breakpoint-max(md) {
    flex-direction: row;
  }

  .canvas {
    width: 100%;
    height: auto;
    display: none;
  }
}
.camera {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  &.hide {
    display: none !important;
  }
  &.show {
    display: flex;
  }
  .block-link {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .spinner {
    &.hide {
      display: none;
    }
    &.show {
      display: block;
      &:before {
        content: "?";
      }
    }
  }
}
.hide {
  display: none;
}
.show {
  display: block;
}
///spinner
///
#loader {
  &.show {
    position: absolute;
    background: rgba(186, 13, 13, 0.9);
    z-index: 99999999999999999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      background-size: cover;
      .plane {
        animation: move -4s cubic-bezier(1, -0.5, 1, -0.5) infinite normal;
        background-size: cover;
        width: 140px;
        height: 80px;
        animation: scroll-x var(--duration) linear infinite;
      }
      #loading {
        width: 50px;
        height: 50px;
        border: 3px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
      }
    }
  }
}

input[type="text"]:disabled {
  background: var(--primary-color-light);
  &.error {
    background: var(--chart-red-light);
    color: var(--primary-color-light);
  }
  &.success {
    background: var(--chart-green-light);
    color: var(--primary-color-dark);
  }
}
.align-left {
  text-align: left;
  p {
    margin: 0;
  }
}
.ReactModal__Overlay {
  z-index: 999;
  background: #ffffff;
  .ReactModal__Content {
    padding: 0rem !important;
  }
}

header {
  position: sticky;
  top: 0;
  background: #ffffff;

  z-index: 999;
  box-shadow: var(--section-shadow);
  .close {
    float: right;
    margin: 0.5rem;
  }
  img {
    margin: 1rem;
  }
}
main {
  margin: 1rem;
}

@media print {
  body *:not(.printable, .printable *) {
    // hide everything but printable elements and their children
    display: none;
  }
}
.user {
  white-space: pre-wrap;
  display: block;
  word-wrap: break-word;
}
table.bom tr td p {
  margin-bottom: 0;
}

.bg-blue {
  background: #70a2bf !important;
  opacity: 0.5;
}
.bg-light-gray {
  background-color: #f4f4f4 !important;
}
.border-light-gray {
  border-color: #70a2bf !important;
  opacity: 0.5;
}
.ReactModalPortal {
  z-index: 999;
}

/*Textbox*/
// .ck-editor__editable {
//   //min-height: 10  0px;
// }

/*ZOOM effect*/
.zoom img {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover img {
  transform: scale(1.5);
}
